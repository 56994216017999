/* Update config below to map each browser language code to a locale.
 * For example, if you want all MENAI languages to be directed to /ar-ae/,
 * then map all their browser language codes to "ar-ae"
 */

export const browserAcceptLanguageTobcpLocale = {
  "ar-ae": "ar-ae",
  ar: "ar-ae",
  "ar-bh": "ar-ae",
  bh: "ar-ae",
  "ar-dz": "ar-ae",
  dz: "ar-ae",
  "ar-eg": "ar-ae",
  eg: "ar-ae",
  "ar-iq": "ar-ae",
  iq: "ar-ae",
  "ar-jo": "ar-ae",
  jo: "ar-ae",
  "ar-kw": "ar-ae",
  kw: "ar-ae",
  "ar-lb": "ar-ae",
  lb: "ar-ae",
  "ar-ma": "ar-ae",
  ma: "ar-ae",
  "ar-om": "ar-ae",
  om: "ar-ae",
  "ar-qa": "ar-ae",
  qa: "ar-ae",
  "ar-sa": "ar-ae",
  sa: "ar-ae",
  "ar-tn": "ar-ae",
  tn: "ar-ae",
  "cs-cz": "cs-cz",
  cz: "cs-cz",
  "de-de": "de-de",
  de: "de-de",
  "el-gr": "el-gr",
  el: "el-gr",
  "en-us": "en-us",
  en: "en-us",
  "en-gb": "en-gb",
  "en-pl": "en-pl",
  "en-sg": "en-sg",
  sg: "en-sg",
  "es-mx": "es-mx",
  "es-es": "es-es",
  es: "es-es",
  "es-ar": "es-ar",
  "fr-fr": "fr-fr",
  fr: "fr-fr",
  "hu-hu": "hu-hu",
  hu: "hu-hu",
  "id-id": "id-id",
  id: "id-id",
  "it-it": "it-it",
  it: "it-it",
  "ja-jp": "ja-jp",
  ja: "ja-jp",
  "ko-kr": "ko-kr",
  ko: "ko-kr",
  "pl-pl": "pl-pl",
  pl: "pl-pl",
  "pt-br": "pt-br",
  pt: "pt-br",
  "ro-ro": "ro-ro",
  ro: "ro-ro",
  "ru-ru": "ru-ru",
  ru: "ru-ru",
  "th-th": "th-th",
  th: "th-th",
  "tr-tr": "tr-tr",
  tr: "tr-tr",
  "vi-vn": "vi-vn",
  vi: "vi-vn",
  "zh-tw": "zh-tw",
  "zh-cn": "zh-cn",
};
